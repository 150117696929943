import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Tracking from './tracking';
import Header from './header';
import SEO from './seo';
import Footer from './footer';
import './layout.css';

const tracking = new Tracking();

const Layout = ({ children }) => {
  useEffect(() => {
    if (typeof window !== 'undefined' && !tracking.isInit) {
      tracking.init();
    }
  }, []);

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <SEO />
      <Header siteTitle={data.site.siteMetadata.title} />
      {children}
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;