class Tracking {
  constructor(clientId = null) {
    this.isInit = false;
    this.validityInterval = Math.round (new Date() / 1000 / 3600 / 24 / 4);
    this.clientId = clientId ?? `unknown_${new Date().getTime()}`;
  }

  /* eslint-disable */
  cyrb53 = function(str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed,
       h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
       ch = str.charCodeAt(i);
       h1 = Math.imul(h1 ^ ch, 2654435761);
       h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ h1 >>> 16, 2246822507) ^ Math.imul(h2 ^ h2 >>> 13, 3266489909);
    h2 = Math.imul(h2 ^ h2 >>> 16, 2246822507) ^ Math.imul(h1 ^ h1 >>> 13, 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  };
  /* eslint-enable */

  async getClientID() {
    const response = await fetch(`https://icanhazip.com`);
    const clientIp = await response.text();

    let clientId = clientIp + ';';

    if (typeof window !== 'undefined') {
      clientId += window.location.host + ';';
    }

    if (typeof navigator !== 'undefined') {
      clientId += navigator.userAgent + ';' + navigator.language + ';';
    }

    clientId += this.validityInterval;

    return clientId;
  }

  /* eslint-disable */
  async init() {
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

    const clientIDSource = await this.getClientID();
    this.clientIDHashed = await this.cyrb53(clientIDSource);

    ga('create', process.env.GATSBY_GA_ID, {
      storage: 'none',
      clientId: this.clientIDHashed
    });

    ga('set', 'anonymizeIp', true);
    this.sendPageView();
    this.isInit = true;
  }

  sendPageView() {
    ga('send', 'pageview', window.location.pathname, {
      title: document.title
    });
  }

  sendEvent(category, action, label) {    
    ga('send', 'event', category, action, label);
  }
  /* eslint-enable */
};

export default Tracking;