import React from 'react';

const Footer = () => (
  <footer className='footer'>
    This website is not affiliated with ReactJS or Facebook
  </footer>
);

Footer.propTypes = {
};

Footer.defaultProps = {
};

export default Footer;
