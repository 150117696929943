import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

const Header = ({ siteTitle }) => (
  <header className='header'>
    <Link className='logo' to='/'>{siteTitle}</Link>

    <nav className='main-navigation'>
      <Link to='/about'>About</Link>
      <Link to='/contribute'>Add architecture</Link>
      <Link to='/'>Github</Link>
    </nav>
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
